<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid">
            <div class="md:col-8 sm:col-12 comp-grid">
              <div class="card">
                <div class="text-xl text-primary font-bold">Edit Campus</div>
              </div>
              <!-- <Breadcrumb :home="{
                icon: 'pi pi-arrow-left text-lg mr-3',
                label: 'Back',
                command: () => {
                  $router.go(-1);
                },
              }" :model="[
                {
                  label: 'Campuses',
                  to: '/institution_campuses',
                },
                {
                  label: 'Edit',
                  to: `/institution_campuses/edit/${id}`,
                },
              ]" /> -->
            </div>
          </div>
        </div>
      </section>
    </template>

    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="sm:col-12 md:col-8 comp-grid">
            <div class="card nice-shadow-18">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              Campus Name *
                            </div>
                            <div class="">
                              <InputText ref="ctrlcampus_name" v-model.trim="formData.campus_name" label="Campus Name"
                                type="text" placeholder="Enter Campus Name" class="w-full"
                                :class="getErrorClass('campus_name')">
                              </InputText>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">Campus Code</div>
                            <div class="">
                              <InputText ref="ctrlcampus_code" v-model.trim="formData.campus_code" label="Campus Code"
                                type="text" placeholder="Enter Campus Code" class="w-full" disabled
                                :class="getErrorClass('campus_code')">
                              </InputText>
                            </div>
                          </div>
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              Campus Description
                            </div>
                            <div class="">
                              <Textarea :class="getErrorClass('campus_description')" class="w-full"
                                ref="ctrlcampus_description" rows="2" v-model="formData.campus_description"
                                placeholder=" Campus Description" type="textarea">
                              </Textarea>
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              Start Of Accreditation
                            </div>
                            <div id="ctrl-start_of_accreditation-holder">
                              <Calendar :showButtonBar="true" class="w-full"
                                :class="getErrorClass('start_of_accreditation')" dateFormat="yy-mm-dd"
                                v-model="formData.start_of_accreditation" :showIcon="true" mask="DD/MM/YYYY" outlined
                                dense @dateSelect="validateAccreditationYear" />
                            </div>
                          </div>
                          <div class="col-12 md:col-6">
                            <div class="mb-2 font-bold text-sm">
                              End Of Accreditation
                            </div>
                            <div id="ctrl-end_of_accreditation-holder">
                              <Calendar :showButtonBar="true" class="w-full"
                                :class="getErrorClass('end_of_accreditation')" dateFormat="yy-mm-dd"
                                v-model="formData.end_of_accreditation" :showIcon="true" mask="DD/MM/YYYY" outlined
                                dense @dateSelect="validateAccreditationYear" />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="mb-4 font-bold text-sm">
                              Is this an accredited campus? *
                            </div>
                            <div id="ctrl-is_accredited-holder">
                              <div class="field-radiobutton" v-for="option of $menus.yes_or_noItems"
                                :key="option.value">
                                <RadioButton :class="getErrorClass('is_accredited')" :id="option.value"
                                  name="ctrlis_accredited" :value="option.value" v-model="formData.is_accredited" />
                                <label :for="option.value">{{ option.label }} </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                        </div>
                      </form>
                      <slot :submit="submit" :saving="saving"></slot>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- <div class="sm:col-12 md:col-4">
            <div class="sticky-item"><file-svg-page></file-svg-page></div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { EditPageMixin } from "../../mixins/editpage.js";
import { PageMixin } from "../../mixins/page.js";
// import fileSvgPage from "../custom/file_svg.vue";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editInstitutioncampusesPage",
  // components: { fileSvgPage },
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "institution_campuses",
    },
    idName: {
      type: String,
      default: "institution_campus_id",
    },
    routeName: {
      type: String,
      default: "institution_campusesedit",
    },
    pagePath: {
      type: String,
      default: "institution_campuses/edit",
    },
    apiPath: {
      type: String,
      default: "institution_campuses/edit",
    },
  },
  data() {
    return {
      formData: {
        campus_name: "",
        campus_description: "",
        campus_code: "",
        start_of_accreditation: null,
        end_of_accreditation: null,
        is_accredited: "",
      },
      submitted: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Institution Campus";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["institution_campuses/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("institution_campuses/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      campus_name: { required },
      campus_description: {},
      campus_code: {},
      start_of_accreditation: {},
      end_of_accreditation: {},
      is_accredited: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("institution_campuses", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          // if (this.redirect) this.navigateTo(`/institution_campuses`);
          this.$router.push(this.$route.path);

        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = { campus_name: "", campus_description: "", campus_code: "", start_of_accreditation: null, end_of_accreditation: null, is_accredited: "" };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() { },
  async created() { },
};
</script>
<style scoped></style>
